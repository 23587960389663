import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Icon } from '@fairygodboss/ui';

import './CountdownWidget.scss';

export default function CountdownWidget({ zIndex = 4 }) {
    const [isClient, setIsClient] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [closeWidget, setCloseWidget] = useState(false);
    const [isTimeUp, setIsTimeUp] = useState(false);

    useEffect(() => {
        setIsClient(true); // Runs only on client after hydration
    }, []);

    // Target date in EDT (Eastern Time)
    const targetDate = '2025-05-06T10:00:00';

    const calculateTimeLeft = () => {
        const now = DateTime.now().setZone('America/New_York');
        const target = DateTime.fromISO(targetDate, {
            zone: 'America/New_York',
        });

        const diff = target.diff(now, ['days', 'hours', 'minutes', 'seconds']);
        // If time is up, return null but it will still show the counter after 15 mins time is up (900000 milliseconds)
        if (diff.toMillis() + 900000 <= 0) return null;

        return {
            days: Math.floor(diff.days),
            hours: Math.floor(diff.hours),
            minutes: Math.floor(diff.minutes),
            seconds: Math.floor(diff.seconds),
        };
    };

    useEffect(() => {
        const updateTimer = () => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);
            setIsTimeUp(newTimeLeft === null);
        };

        updateTimer(); // Set immediately on mount
        const interval = setInterval(updateTimer, 3000);

        return () => clearInterval(interval);
    }, []);

    const timerSegment = (value = 0, label = '') => {
        return (
            <div className="segment-wrapper">
                <div className="segment-value">
                    {value < 10 && '0'}
                    {value < 0 ? '0' : value}
                </div>
                <div className="segment-label">{label}</div>
            </div>
        );
    };

    const renderTimer = () => {
        const { days, hours, minutes } = timeLeft || {};
        return (
            <div className="countdown-timer">
                {timerSegment(days, 'DAYS')}:{timerSegment(hours, 'HOURS')}:
                {timerSegment(minutes, 'MINS')}
            </div>
        );
    };

    const renderCloseButton = (hide = true) => {
        if (hide) return null;
        return (
            <button
                type="button"
                onClick={() => setCloseWidget(true)}
                className="close-icon"
            >
                <Icon variant="close_16" outlineColor="white" />
            </button>
        );
    };

    const renderTicketIcon = (fontSize = '16px', color = 'white') => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={fontSize}
                height={fontSize}
                viewBox="0 0 19 13"
                fill="none"
            >
                <path
                    d="M0 0.417297V5.07928H0.665998C1.45854 5.07928 1.99799 5.61874 1.99799 6.41128C1.99799 7.20382 1.45854 7.74328 0.665998 7.74328H0V12.4053H18.6479V7.74328H17.982C17.1894 7.74328 16.65 7.20382 16.65 6.41128C16.65 5.61874 17.1894 5.07928 17.982 5.07928H18.6479V0.417297H0ZM1.332 1.74929H17.316V3.8725C16.1738 4.1722 15.318 5.17452 15.318 6.41128C15.318 7.64804 16.1738 8.65037 17.316 8.95073V11.0733H1.332V8.95007C2.47418 8.65037 3.32999 7.64804 3.32999 6.41128C3.32999 5.17452 2.47418 4.1722 1.332 3.87183V1.74929Z"
                    fill={color}
                />
            </svg>
        );
    };

    if (!isClient || isTimeUp === true || closeWidget) return null;

    return (
        <div style={{ zIndex }} className="widget-wrapper">
            <div className="widget-overlay" style={{ zIndex: zIndex - 1 }} />
            <div className="widget-content" style={{ zIndex }}>
                {renderCloseButton()}
                <div className="widget-title">
                    <div className="featured-text">INSPIRATION SUMMIT</div>
                    <div>Starts In</div>
                </div>
                {renderTimer()}
                <a
                    target="_blank"
                    rel="noreferrer"
                    className="widget-cta"
                    href="https://events.ringcentral.com/events/inspiration-summit-2025-0ae27d6c-912a-4be5-98cf-6b92e122ba69/registration?utm_source=fgb&utm_medium=widget"
                >
                    {renderTicketIcon()} Register Now
                </a>
            </div>
        </div>
    );
}
